import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authAction, homeAction } from "redux/modules";
import { Redirect } from "react-router-dom";
import Modal from "react-responsive-modal";

import showMessage from "hooks/showMessage";
import showErrMessage from "hooks/showErrMessage";
import { IoArrowForward } from "react-icons/io5";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";

import Poll from "assets/images/new-design/bg-section-poll.png";

import "./style.scss";

const DisclaimerSurvey = () => {
  const dispatch = useDispatch();

  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const isLoading = useSelector(store => store.disclaimer.isLoading);
  const surveyList = useSelector(store => store.home.surveyList);

  const [selectedChoice, setSelectedChoice] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);

  const [open, setOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [disclaimerShow, setDisclaimerShow] = useState(true);

  const closeModal = () => setOpen(!open);

  useEffect(() => {
    if (surveyList && surveyList.length > 0) {
      const filteredSurveyList = surveyList.filter(
        item => item.answeredSurvey === false
      );
      if (filteredSurveyList && filteredSurveyList.length === 0) {
        setDisclaimerShow(false);
        setRedirect(true);
      }
    }

    return () => {};
  }, [surveyList]);

  useEffect(() => {
    dispatch(
      homeAction.getSurveyByFirmId(
        employeeFirm.firm.id,
        employeeFirm.ercCode,
        "AFTERLOGIN"
      )
    );

    return () => {};
  }, []);

  const handleSelectChoice = (surveyId, choiceId) => {
    setSelectedChoice(prevChoices => {
      const existingChoiceIndex = prevChoices.findIndex(
        choice => choice.surveyId === surveyId
      );
      if (existingChoiceIndex > -1) {
        // Anket için zaten bir seçim yapılmışsa, onu güncelle
        const updatedChoices = [...prevChoices];
        updatedChoices[existingChoiceIndex] = { surveyId, choiceId };
        return updatedChoices;
      } else {
        // Yeni bir anket seçimi ekle
        return [...prevChoices, { surveyId, choiceId }];
      }
    });
  };

  const saveSurvey = (surveyId, employeeFirmId, firmId, surveyType, e) => {
    e.preventDefault();
    const surveyChoice = selectedChoice.find(
      choice => choice.surveyId === surveyId
    );
    if (!surveyChoice) {
      showErrMessage("Seçenek seçiniz.");
      debugger;
    } else {
      setDisabledButton(true);
      dispatch(
        homeAction.saveSurveyAnswer(
          surveyId,
          [surveyChoice.choiceId], // Tek bir anketin seçimi
          employeeFirmId,
          firmId,
          surveyType
        )
      ).then(res => {
        showMessage(`Ankete cevap verdiniz.`);

        dispatch(
          homeAction.getSurveyByFirmId(
            employeeFirm.firm.id,
            employeeFirm.ercCode,
            surveyType
          )
        )
          .then(response => {
            setDisabledButton(false);
          })
          .catch(err => {
            setDisabledButton(false);
          });
      });
    }
  };

  const bg = {
    overlay: {
      background: "#424242"
    }
  };

  if (redirect) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <div>
      <GlobalLoader isLoading={isLoading} />
      {disclaimerShow && (
        <Modal
          open={!open}
          onClose={closeModal}
          role="dialog"
          closeOnEsc={false}
          showCloseIcon={false}
          closeOnOverlayClick={false}
          styles={bg}
          modalId="disclaimer-survey-modal-area"
        >
          <div className="max-h-[800px] p-[25px] overflow-y-scroll">
            {surveyList &&
              surveyList
                .filter(item => item.answeredSurvey === false)
                .map(item => (
                  <div key={item.id} className="mb-4">
                    <div className="relative overflow-hidden rounded-xl bg-slate-100 py-8 max-lg:px-6 lg:rounded-br-[100px] lg:rounded-tl-[100px] lg:py-20">
                      <div className="relative z-10 grid grid-cols-12 max-lg:gap-y-12">
                        <div className="col-span-12 max-lg:text-center lg:col-span-5 lg:col-start-2 lg:px-12 xl:col-span-4 xl:col-start-3">
                          <h2 className="text-3xl font-semibold text-slate-700 lg:text-[48px]">
                            Anket
                          </h2>
                          <p className="mt-4 text-[18px] text-slate-400 lg:text-[24px]">
                            {item.description}
                          </p>
                        </div>

                        <div className="col-span-12 lg:col-span-5 lg:px-12 xl:col-span-4">
                          <div className="flex flex-col gap-6 text-slate-500">
                            <form
                              name="anket"
                              className="survey-form"
                              onSubmit={e =>
                                saveSurvey(
                                  item.id,
                                  employeeFirm.id,
                                  employeeFirm.firm.id,
                                  "AFTERLOGIN",
                                  e
                                )
                              }
                            >
                              {item.surveyChoices?.map(choice => (
                                <div
                                  className="flex cursor-pointer gap-4 mb-4"
                                  key={choice.id}
                                >
                                  <input
                                    tabIndex="-1"
                                    type="radio"
                                    name={`survey-${item.id}`} // Her anket için farklı name kullanımı
                                    id={`choice-${choice.id}`}
                                    className={
                                      selectedChoice.some(
                                        choice =>
                                          choice.surveyId === item.id &&
                                          choice.choiceId === choice.id
                                      )
                                        ? "radio radio-success"
                                        : "radio"
                                    }
                                    value={choice.id}
                                    onClick={() =>
                                      handleSelectChoice(item.id, choice.id)
                                    }
                                  />

                                  <label
                                    className="mb-0"
                                    onClick={() =>
                                      handleSelectChoice(item.id, choice.id)
                                    }
                                    htmlFor={`choice-${choice.id}`}
                                  >
                                    {choice.name}
                                  </label>
                                </div>
                              ))}
                              <div className="max-lg:text-center">
                                <button
                                  type="submit"
                                  className="!mt-8 inline-flex items-center gap-2 rounded-full border-2 border-slate-700 bg-white !px-6 !py-3 text-sm text-slate-700 !lg:mt-12"
                                  disabled={disabledButton}
                                >
                                  Oy Ver <IoArrowForward className="h-4 w-4" />
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                      <div className="absolute left-0 top-0 z-0 h-full w-full bg-[url('assets/images/new-design/pattern.png')] opacity-40"></div>

                      <img
                        src={Poll}
                        alt=""
                        className="absolute bottom-0 left-0 z-0 transform max-xl:scale-75 max-lg:hidden lg:-translate-x-[44%] lg:translate-y-[45%] xl:-translate-x-[42%] xl:translate-y-[40%]"
                      />
                    </div>
                  </div>
                ))}
          </div>
        </Modal>
      )}
    </div>
  );
};
export default DisclaimerSurvey;
