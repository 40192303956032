import * as homeTypes from "./home.type";

import { API } from "../../../services/http.service";

const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

export const getAnnouncementByFirmId = (id, employeeId) => async dispatch => {
  dispatch({ type: homeTypes.GET_ANNOUNCEMENTLIST_BY_FIRMID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/announcementList/${id}/${employeeId}`,
      config
    );
    dispatch({
      type: homeTypes.GET_ANNOUNCEMENTLIST_BY_FIRMID_FULFILLED,
      payload: response
    });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: homeTypes.GET_ANNOUNCEMENTLIST_BY_FIRMID_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};

export const getSurveyByFirmId = (
  firmId,
  ercCode,
  surveyType
) => async dispatch => {
  dispatch({ type: homeTypes.GET_SURVEYLIST_BY_FIRMID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/survey/${firmId}/${ercCode}/${surveyType}`,
      config
    );
    dispatch({
      type: homeTypes.GET_SURVEYLIST_BY_FIRMID_FULFILLED,
      payload: response
    });

    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: homeTypes.GET_SURVEYLIST_BY_FIRMID_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};

export const saveSurveyAnswer = (
  survey,
  surveyChoice,
  employeeFirmId,
  firmId,
  surveyType
) => async dispatch => {
  dispatch({ type: homeTypes.SAVE_SURVEY_ANSWER_PENDING });

  const data = { survey, surveyChoice, employeeFirmId, surveyType };
  try {
    const response = await API.post(`/survey/save`, data);
    const interaction = {
      message: "Ankete katıldınız",
      type: "notification"
    };

    dispatch({
      type: homeTypes.SAVE_SURVEY_ANSWER_FULFILLED,
      payload: response,
      interaction
    });

    dispatch(getSurveyByFirmId(firmId, ercCode, surveyType));
    dispatch({
      type: homeTypes.SELECTED_OPTION,
      payload: null
    });
  } catch (error) {
    dispatch({ type: homeTypes.SAVE_SURVEY_ANSWER_REJECTED, payload: error });
  }
};

export const getDisclaimerByFirmId = (id, employeeId) => async dispatch => {
  dispatch({ type: homeTypes.GET_DISCLAIMER_BY_FIRMID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/disclaimer/${id}/${employeeId}`,
      config
    );
    dispatch({
      type: homeTypes.GET_DISCLAIMER_BY_FIRMID_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: homeTypes.GET_DISCLAIMER_BY_FIRMID_REJECTED,
      payload: error
    });
  }
};

export const changeOption = selectedOption => async dispatch => {
  dispatch({ type: homeTypes.SELECTED_OPTION, payload: selectedOption });
};

export const saveDisclaimer = (
  disclaimer,
  employeeFirmId,
  firmId
) => async dispatch => {
  dispatch({ type: homeTypes.SAVE_DISCLAIMER_CHECK_PENDING });

  const data = { disclaimer, employeeFirmId, firmId };
  try {
    const response = await API.post(`/disclaimer/save`, data);

    dispatch({
      type: homeTypes.SAVE_DISCLAIMER_CHECK_FULFILLED,
      payload: response
    });

    //    dispatch(getDisclaimerByFirmId(firmId));
  } catch (error) {
    dispatch({
      type: homeTypes.SAVE_DISCLAIMER_CHECK_REJECTED,
      payload: error
    });
  }
};

export const getSpecialActivityByFirmId = id => async dispatch => {
  dispatch({ type: homeTypes.GET_SPECIAL_ACTIVITY_BY_FIRMID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/specialActivityList/${id}`,
      config
    );
    dispatch({
      type: homeTypes.GET_SPECIAL_ACTIVITY_BY_FIRMID_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: homeTypes.GET_SPECIAL_ACTIVITY_BY_FIRMID_REJECTED,
      payload: error
    });
  }
};

export const getRamadanWidget = employeeFirmId => async dispatch => {
  dispatch({ type: homeTypes.GET_RAMADAN_WIDGET_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/competition/ramadan/${employeeFirmId}`,
      config
    );
    dispatch({
      type: homeTypes.GET_RAMADAN_WIDGET_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: homeTypes.GET_RAMADAN_WIDGET_REJECTED,
      payload: error
    });
  }
};

export const setRamadanWidget = (
  employeeFirmId,
  provinceId
) => async dispatch => {
  dispatch({ type: homeTypes.SET_RAMADAN_WIDGET_PENDING });

  try {
    const response = await API.post(
      `${REQUEST_URL}/employeeFirm/city/${employeeFirmId}/${provinceId}`,
      config
    );
    dispatch({
      type: homeTypes.SET_RAMADAN_WIDGET_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: homeTypes.SET_RAMADAN_WIDGET_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const getPriceList = () => async dispatch => {
  dispatch({ type: homeTypes.GET_PRICE_LIST_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/brandStyleListAll`, config);
    dispatch({
      type: homeTypes.GET_PRICE_LIST_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: homeTypes.GET_PRICE_LIST_REJECTED,
      payload: error
    });
  }
};

export const getHomePriceList = () => async dispatch => {
  dispatch({ type: homeTypes.GET_HOME_PRICE_LIST_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/brandDashStyleListAll`,
      config
    );
    dispatch({
      type: homeTypes.GET_HOME_PRICE_LIST_FULFILLED,
      payload: response
    });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: homeTypes.GET_HOME_PRICE_LIST_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};

export const getIysPopup = employeeFirmId => async dispatch => {
  dispatch({ type: homeTypes.GET_IYS_POPUP_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/iysPopup/${employeeFirmId}`,
      config
    );
    dispatch({
      type: homeTypes.GET_IYS_POPUP_FULFILLED,
      payload: response
    });

    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: homeTypes.GET_IYS_POPUP_REJECTED,
      payload: error
    });

    return Promise.reject(error);
  }
};

export const saveIysPopup = employeeFirmId => async dispatch => {
  dispatch({ type: homeTypes.SET_IYS_POPUP_PENDING });

  const data = {
    employeeFirmId: employeeFirmId
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/save/iysPopup`,
      data,
      config
    );
    dispatch({
      type: homeTypes.SET_IYS_POPUP_FULFILLED,
      payload: response
    });

    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: homeTypes.SET_IYS_POPUP_REJECTED,
      payload: error
    });

    return Promise.reject(error);
  }
};

export const getHomeOrderInfo = employeeFirmId => async dispatch => {
  dispatch({ type: homeTypes.GET_HOME_ORDER_INFO_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/orderNumber/${employeeFirmId}`,
      config
    );
    dispatch({
      type: homeTypes.GET_HOME_ORDER_INFO_FULFILLED,
      payload: response
    });

    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: homeTypes.GET_HOME_ORDER_INFO_REJECTED,
      payload: error
    });

    return Promise.reject(error);
  }
};
